import { adminConfig } from '@/constant/configAdmin.js';
import axios from "axios";
const queryString = require('query-string');

export default {
    getElementsList(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${adminConfig.getListDepartment}?${queryParams}`,
            withCredentials: true,
        });
    },
    addUserToDepartment(deptId, userId) {
        return axios({
            method: "GET",
            url: adminConfig.addUserToDepartment.format(deptId, userId),
            withCredentials: true,
        });
    },
    removeUserToDepartment(deptId, userId) {
        return axios({
            method: "GET",
            url: adminConfig.removeUserToDepartment.format(deptId, userId),
            withCredentials: true,
        });
    },
    updateDepartmentOdds(deptId, percent) {
        return axios({
            method: "GET",
            url: adminConfig.updateDepartmentOdds.format(deptId, percent),
            withCredentials: true,
        });
    },
}